import { ajaxCall } from "@/shared/config/ajax-call";
import { handleErrorByMessage } from "@/shared/services/with-store-error-handlers";
import { makeLogger } from "@/shared/consola";
// eslint-disable-next-line no-unused-vars
const logger = makeLogger("user-themes.service");

const THEMES_ENDPOINT = `/themes`;

export const userThemesService = {
    getAllForUser() {
        return ajaxCall.get(THEMES_ENDPOINT).catch(handleErrorByMessage());
    },
    create(theme) {
        return ajaxCall.post(THEMES_ENDPOINT, theme).catch(handleErrorByMessage());
    },
    remove(id) {
        return ajaxCall.delete(`${THEMES_ENDPOINT}/${id}`).catch(handleErrorByMessage());
    },
    update(id, theme) {
        return ajaxCall.patch(`${THEMES_ENDPOINT}/${id}`, theme).catch(handleErrorByMessage());
    },
};
