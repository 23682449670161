import { nextTick } from "vue";
import mitt from "mitt";

const emitter = mitt();

export const EventBus = {
    $on: (type, handler) => emitter.on(type, handler),
    $off: (type, handler) => emitter.off(type, handler),
    $once: (type, handler) => {
        const handlerPatch = (ev) => {
            handler(ev);
            emitter.off(type, handlerPatch);
        };
        emitter.on(type, handlerPatch);
    },
    $emit: (type, event) => emitter.emit(type, event),
    $nextTick: (handler) => nextTick(handler),
};
