import { makeLogger } from "@/shared/consola";

const logger = makeLogger("faq.router");

import(/* webpackChunkName: "faq_app" */ "./faq.route.navigate.js");

const FaqApp = () => import(/* webpackChunkName: "faq_app" */ "./FaqApp.vue");
const FaqProjectPage = () => import(/* webpackChunkName: "faq_app" */ "./pages/FaqProjectPage.vue");
const FaqListPage = () => import(/* webpackChunkName: "faq_app" */ "./pages/FaqListPage.vue");

// Sidebar menus:
const SidebarQuestions = () =>
    import(/* webpackChunkName: "faq_app" */ "@/apps/faq/project/sidebar/SidebarQuestions.vue");
const SidebarLayout = () =>
    import(/* webpackChunkName: "faq_app" */ "@/apps/faq/project/sidebar/SidebarLayout.vue");
const SidebarStyles = () =>
    import(/* webpackChunkName: "faq_app" */ "@/apps/faq/project/sidebar/SidebarStyles.vue");
const SidebarImport = () =>
    import(/* webpackChunkName: "faq_app" */ "@/apps/faq/project/sidebar/SidebarImport.vue");

export const FAQ_ROUTE_NAMES = {
    project: "faq-project",
    projectQuestions: "faq-project-questions",
    projectLayout: "faq-project-layout",
    projectStyles: "faq-project-styles",
    projectImport: "faq-project-import",
    list: "faq-list",
};

export const FAQ_PREVIEW_KIND = {
    faq: "faq",
};

export const FAQ_FIRST_SEGMENT = "faq";

export const faqRoutes = [
    {
        path: `/${FAQ_FIRST_SEGMENT}`,
        component: FaqApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: FAQ_ROUTE_NAMES.project,
                component: FaqProjectPage,
                children: [
                    {
                        path: "questions",
                        name: FAQ_ROUTE_NAMES.projectQuestions,
                        component: SidebarQuestions,
                    },
                    {
                        path: "import",
                        name: FAQ_ROUTE_NAMES.projectImport,
                        component: SidebarImport,
                    },
                    {
                        path: "layout",
                        name: FAQ_ROUTE_NAMES.projectLayout,
                        component: SidebarLayout,
                    },
                    {
                        path: "styles",
                        name: FAQ_ROUTE_NAMES.projectStyles,
                        component: SidebarStyles,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                FAQ_ROUTE_NAMES.projectQuestions
                            );
                            return {
                                name: FAQ_ROUTE_NAMES.projectQuestions,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: FAQ_ROUTE_NAMES.list,
                component: FaqListPage,
            },
        ],
    },
];
