import { mapAjaxError } from "@/shared/errors/ajax-errors-mapper";
import { makeLogger } from "@/shared/consola";
import "./common.errors";
import { t } from "@/i18n";

const EXTRACT_RESPONSE = "response";

export class ServerResponseError extends Error {
    lang = ""; // must be empty at start (due to import concurrency problem)
    status = 0;
    logger = makeLogger("ServerResponseError");
    errorToken = "UNKNOWN_ERROR";

    constructor(baseError) {
        super(baseError.message);
        for (const key of Object.keys(baseError)) {
            this[key] = baseError[key];
            if (key === EXTRACT_RESPONSE) {
                const { status, data, statusText } = baseError[EXTRACT_RESPONSE];
                const { statusCode, message } = data;
                this.errorToken = message || statusText || data;
                this.status = statusCode || status;
            }
        }
        this.logger.trace(
            `Errored response[${ServerResponseError.lang}]:`,
            this.errorToken,
            `(${this.status})`,
            this.translatedMessage
        );
    }

    static setAjaxErrorLanguage(lang) {
        ServerResponseError.lang = lang;
    }

    get translatedMessage() {
        if (this.errorToken?.startsWith?.("ERR_INT_EASY_ID_connect")) {
            return t("easyid.cannot_connect");
        }
        return mapAjaxError(this.errorToken, ServerResponseError.lang);
    }
}
