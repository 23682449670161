import { registerServiceErrors } from "@/shared/errors/ajax-errors-mapper";

export const ERR_USER_NOT_AN_OWNER = "ERR_USER_NOT_AN_OWNER";
export const ERR_LOGIN_REQUIRED = "ERR_LOGIN_REQUIRED";
export const ERR_ACCESS_PLAN_BELOW_REQUIRED = "ERR_ACCESS_PLAN_BELOW_REQUIRED";

registerServiceErrors({
    Unauthorized: {
        pl: "Nie posiadasz odpowiednich uprawnień",
        en: "Unauthorized",
    },
    ERR_FIELD: {
        pl: "Pole",
        en: "Field",
    },
    // ERR_INVALID
    ERR_INVALID_MUST_BE_NUMBER: {
        pl: "Wartość powinna być numeryczna",
        en: "Value should be a number",
    },
    ERR_INVALID_MUST_MUST_BE_STRING: {
        pl: "Wartość powinna być tekstowa",
        en: "Value should be a text",
    },
    ERR_INVALID_MUST_BE_STRING_ARRAY: {
        pl: "Wartość powinna być elementami wymienionymi po przecinku",
        en: "Value should be comma separated elements",
    },
    ERR_INVALID_ALL_ELEMENTS_MUST_BE_EMAILS: {
        pl: "Podaj prawidłowe adresy e-mail",
        en: "Provide right e-mail addresses",
    },
    ERR_INVALID_FILE_WRONG_TYPE: {
        pl: "Niewłaściwy typ pliku",
        en: "Wrong file type",
    },
    ERR_INVALID_FILE_WRONG_SIZE: {
        pl: "Niewłaściwy rozmiar pliku",
        en: "Wrong file size",
    },
    UUID_TAKEN: {
        en: "This url is already taken",
        pl: "Ten url jest już zajęty",
    },
    [ERR_USER_NOT_AN_OWNER]: {
        en: "You are not an owner of this project",
        pl: "Nie jesteś właścicielem tego projektu",
    },
    [ERR_LOGIN_REQUIRED]: {
        en: "You need to log in to perform this action",
        pl: "Musisz się zalogować, aby wykonać tę akcję",
    },
    ERR_THEME_NAME_REQUIRED: {
        en: "Theme name is required",
        pl: "Nazwa motywu jest wymagana",
    },
    [ERR_ACCESS_PLAN_BELOW_REQUIRED]: {
        pl: "Do publikacji projektu wymagany jest zakup płatnego planu",
        en: "To publish the project, you must first acquire a paid plan",
    },
});
