export const presetThemesService = {
    getAllColorPresets($l) {
        return [
            {
                value: "user",
                name: $l({ en: "Custom", pl: "Własny" }),
            },
            {
                value: "light",
                name: $l({ en: "Light", pl: "Jasny" }),
                colors: {
                    color1: "#fafafa",
                    color2: "#ffffff",
                    color3: "#f5f5f5",
                    color4: "#9568d3",
                    title1: "#3c404b",
                    title2: "#3c404b",
                    subtitle1: "#5a6071",
                    subtitle2: "#5a6071",
                    heading: "#3c404b",
                    text: "#838d95",
                    border: "#e0e3e6",
                },
            },
            {
                value: "dark",
                name: $l({ en: "Dark", pl: "Ciemny" }),
                colors: {
                    color1: "#111827",
                    color2: "#1f2937",
                    color3: "#2a313c",
                    color4: "#1c64f2",
                    title1: "#f3f4f6",
                    title2: "#f3f4f6",
                    subtitle1: "#d1d5db",
                    subtitle2: "#d1d5db",
                    heading: "#f3f4f6",
                    text: "#9ca3af",
                    border: "#374151",
                },
            },
            {
                value: "navy-blue",
                name: $l({ en: "Navy blue", pl: "Granatowy" }),
                colors: {
                    color1: "#032880",
                    color2: "#ece0ca",
                    color3: "#f0f0f0",
                    color4: "#99a0b4",
                    title1: "#ece0ca",
                    title2: "#032880",
                    subtitle1: "#b0a99b",
                    subtitle2: "#5a6071",
                    heading: "#3c404b",
                    text: "#6b7176",
                    border: "#cccfd1",
                },
            },
            {
                value: "light-blue",
                name: $l({ en: "Light blue", pl: "Błękitny" }),
                colors: {
                    color1: "#e4edf7",
                    color2: "#f9fafb",
                    color3: "#ffffff",
                    color4: "#2362a3",
                    title1: "#3c404b",
                    title2: "#3c404b",
                    subtitle1: "#5a6071",
                    subtitle2: "#5a6071",
                    heading: "#3c404b",
                    text: "#838d95",
                    border: "#e0e3e6",
                },
            },
            {
                value: "white",
                name: $l({ en: "White", pl: "Biały" }),
                colors: {
                    color1: "#ffffff",
                    color2: "#ffffff",
                    color3: "#ffffff",
                    color4: "#2362a3",
                    title1: "#3c404b",
                    title2: "#3c404b",
                    subtitle1: "#5a6071",
                    subtitle2: "#5a6071",
                    heading: "#3c404b",
                    text: "#838d95",
                    border: "#e0e3e6",
                },
            },
            {
                value: "black",
                name: $l({ en: "Black", pl: "Czarny" }),
                colors: {
                    color1: "#000000",
                    color2: "#000000",
                    color3: "#000000",
                    color4: "#1c64f2",
                    title1: "#f3f4f6",
                    title2: "#f3f4f6",
                    subtitle1: "#d1d5db",
                    subtitle2: "#d1d5db",
                    heading: "#f3f4f6",
                    text: "#9ca3af",
                    border: "#374151",
                },
            },
            {
                value: "pink",
                name: $l({ en: "Pink", pl: "Różowy" }),
                colors: {
                    color1: "#de5499",
                    color2: "#eddcd9",
                    color3: "#f2ebe9",
                    color4: "#264143",
                    title1: "#eddcd9",
                    title2: "#264143",
                    subtitle1: "#f2ebe9",
                    subtitle2: "#264143",
                    heading: "#264143",
                    text: "#657172",
                    border: "#d2cdcb",
                },
            },
            {
                value: "mustard",
                name: $l({ en: "Mustard", pl: "Musztardowy" }),
                colors: {
                    color1: "#333D51",
                    color2: "#e3e1d9",
                    color3: "#CBD0D8",
                    color4: "#D3AC2B",
                    title1: "#e3e1d9",
                    title2: "#333D51",
                    subtitle1: "#d0cdbf",
                    subtitle2: "#424e68",
                    heading: "#333D51",
                    text: "#657172",
                    border: "#aaadb1",
                },
            },
            {
                value: "mint",
                name: $l({ en: "Mint", pl: "Miętowy" }),
                colors: {
                    color1: "#99d6d3",
                    color2: "#ffffff",
                    color3: "#EBF2EC",
                    color4: "#f9345b",
                    title1: "#154279",
                    title2: "#154279",
                    subtitle1: "#3d679b",
                    subtitle2: "#3d679b",
                    heading: "#154279",
                    text: "#3d679b",
                    border: "#dce5de",
                },
            },
            {
                value: "blueberry",
                name: $l({ en: "Blueberry", pl: "Jagodowy" }),
                colors: {
                    color1: "#57648C",
                    color2: "#E5E5E5",
                    color3: "#cac0d8",
                    color4: "#934A5F",
                    title1: "#E5E5E5",
                    title2: "#57648C",
                    subtitle1: "#E5E5E5",
                    subtitle2: "#6775a0",
                    heading: "#57648C",
                    text: "#6775a0",
                    border: "#a196b0",
                },
            },
            {
                value: "green",
                name: $l({ en: "Green", pl: "Zielony" }),
                colors: {
                    color1: "#2b6777",
                    color2: "#f2f2f2",
                    color3: "#ffffff",
                    color4: "#52ab98",
                    title1: "#f2f2f2",
                    title2: "#2b6777",
                    subtitle1: "#dcdcdc",
                    subtitle2: "#5b8893",
                    heading: "#2b6777",
                    text: "#5b8893",
                    border: "#bcd0d5",
                },
            },
            {
                value: "nature",
                name: $l({ en: "Nature", pl: "Natura" }),
                colors: {
                    color1: "#433E49",
                    color2: "#F3E8EB",
                    color3: "#DBC1AD",
                    color4: "#928490",
                    title1: "#DBC1AD",
                    title2: "#433E49",
                    subtitle1: "#a29186",
                    subtitle2: "#686072",
                    heading: "#433E49",
                    text: "#686072",
                    border: "#b19c8b",
                },
            },
        ];
    },
    getAllStylePresets($l) {
        return [
            {
                id: "default",
                value: $l({ en: "Default", pl: "Domyślny" }),
                font: "Inter",
                radius: "6",
            },
            {
                id: "friendly",
                value: $l({ en: "Friendly", pl: "Przyjazny" }),
                font: "Nunito",
                radius: "10",
            },
            {
                id: "classic",
                value: $l({ en: "Classic", pl: "Klasyczny" }),
                font: "Playfair Display",
                radius: "0",
            },
            {
                id: "tech",
                value: $l({ en: "Tech", pl: "Techniczny" }),
                font: "Roboto",
                radius: "4",
            },
        ];
    },
};
