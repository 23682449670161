import axios from "axios";
import { ServerResponseError } from "@/shared/errors/ServerResponseError";

export const ajaxCall = axios.create({
    baseURL: `${process.env.VUE_APP_ORIGIN}/api/`,
    withCredentials: true,
});

ajaxCall.interceptors.response.use(
    (res) => {
        // Unwrap response if possible:
        return res?.data || res;
    },
    (errorData) => {
        return Promise.reject(new ServerResponseError(errorData));
    }
);
