// import { makeLogger } from "@/shared/consola";
import { t } from "@/i18n";

// const logger = makeLogger("alerts.store");

const ALERT_TYPE = {
    error: "error",
    success: "success",
    warning: "warning",
};

export default {
    namespaced: true,
    state: () => ({
        messages: [],
    }),
    actions: {
        showMessage({ state }, { type, title, message }) {
            state.messages.push({ type, title, message });
        },
        showSuccessMessage({ dispatch }, { title = "", message = "" }) {
            dispatch("showMessage", { title, message, type: ALERT_TYPE.success });
        },
        showWarnMessage({ dispatch }, { title = "", message = "" }) {
            dispatch("showMessage", { title, message, type: ALERT_TYPE.warning });
        },
        showErrorMessage({ dispatch }, { title = "", message = "" }) {
            dispatch("showMessage", { title, message, type: ALERT_TYPE.error });
        },
        // Legacy named:
        showServerError({ dispatch }, { message }) {
            dispatch("showErrorMessage", { title: t("shared.server_error"), message });
        },
    },
};
