import axios from "axios";
import { localStorageService, STORAGE_KEY } from "@/shared/services/local-storage.service";

let baseUrl = `${process.env.VUE_APP_ORIGIN}/api/`;

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
});

axiosInstance.interceptors.response.use(
    (res) => res,
    (errorData) => {
        const { response } = errorData;
        if (response) {
            const { status } = response;
            const userId = localStorageService.readValue(STORAGE_KEY.userId);
            if (status === 401 && userId && userId !== "undefined") {
                // TODO: check to remove this if'o-logic?!
            }
        }
        return Promise.reject(errorData);
    }
);

export default axiosInstance;
