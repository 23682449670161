export default class Query {
    constructor(initialQuery) {
        this.query = [];

        if (initialQuery) {
            this.parse(initialQuery);
        }
    }

    parse(queryString) {
        const parts = (queryString.charAt(0) === "?" ? queryString.slice(1) : queryString).split(
            "&"
        );

        parts.forEach((part) => {
            const [key, val] = part.split("=");
            this.set(key, decodeURIComponent(val));
        });
    }

    get string() {
        let output = "?";

        this.query.forEach(({ key, value }) => {
            if (value !== "") {
                output += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
            }
        });

        return output.slice(0, -1);
    }

    get fullUrl() {
        const { location } = window;

        return `${location.origin}/${location.pathname}${this.string}`;
    }

    empty() {
        return this.query.length === 0;
    }

    get(keyToCheck) {
        const item = this.query.find(({ key }) => key === keyToCheck);

        return item ? item.value : null;
    }

    getIndex(keyToCheck) {
        const index = this.query.findIndex(({ key }) => key === keyToCheck);

        return index === -1 ? null : index;
    }

    has(keyToCheck) {
        return this.getIndex(keyToCheck) !== null;
    }

    set(keyToSet, value) {
        this.remove(keyToSet);
        this.query.push({ key: keyToSet, value });
    }

    remove(keyToRemove) {
        this.query = this.query.filter(({ key }) => key !== keyToRemove);
    }
}
