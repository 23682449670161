import { ajaxCall } from "@/shared/config/ajax-call";
import { handleErrorByMessage } from "@/shared/services/with-store-error-handlers";
import { isProdOrStageEnvironment } from "@/environments";
import { makeLogger } from "@/shared/consola";
import store from "@/store";

// BEFORE REFACTOR:
/**
 * Be careful:
 *  - two ajax Instances exist in project:
 *      -- @/shared/config/ajax-call
 *      -- @/shared/config/easyaxios;
 *
 *  There is a rule to refactor to `ajaxCall` but it might be not achievable
 *  due to existing in easyaxios module logic (check it first in users context!!)
 * */

const ENDPOINT = "/users";

const logger = makeLogger("userService");

export const userService = {
    async forgeMagicLink(redirectUrl) {
        logger.debug("start to forgeMagicLink", redirectUrl);
        if (store.getters.isImp) {
            logger.debug("Imp session, not forging magic link, result => ", redirectUrl);
            return { magicUrl: redirectUrl };
        }
        if (isProdOrStageEnvironment) {
            return ajaxCall
                .post(`${ENDPOINT}/forge-magic`, { redirectUrl })
                .then((link) => {
                    logger.debug("link forged", link.magicUrl);
                    return link;
                })
                .catch(handleErrorByMessage());
        }
        // I will not forge magic link for dev environment;
        logger.trace("DEV, not forging magic link, result => ", redirectUrl);
        return { magicUrl: redirectUrl };
    },
};
