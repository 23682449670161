import {
    ERR_LOGIN_REQUIRED,
    ERR_USER_NOT_AN_OWNER,
    ERR_ACCESS_PLAN_BELOW_REQUIRED,
} from "@/shared/errors/common.errors";
import { loginService } from "@/auth/service/login.service";
import { mapAjaxError } from "@/shared/errors/ajax-errors-mapper";
import store from "@/store";
import { makeLogger } from "@/shared/consola";
import { localStorageService, STORAGE_KEY } from "@/shared/services/local-storage.service";
import { RENDER_ROUTE_NAMES } from "@/views/render/render.routes";
import router from "@/router";

import { t } from "@/i18n";
import { userService } from "@/auth/service/user.service";

const logger = makeLogger("handleErrorByMessage");

export function handleErrorByMessage({ rethrow } = { rethrow: true }) {
    return (error) => {
        logger.debug(error);
        store.dispatch("showServerError", error.translatedMessage || error);
        if (rethrow) {
            throw error;
        }
    };
}

export function handleErrorNotAnOwner() {
    return async (error) => {
        const { lang } = store?.state || {};
        if (error.errorToken === ERR_USER_NOT_AN_OWNER) {
            // is User present ? (FIX: do not use Vuex .store [!!!] - as user might not be loaded yet)
            if (localStorageService.readValue(STORAGE_KEY.userId)) {
                // TODO: identity + business decision what next ?
                const decision = await store.dispatch("modals/showConfirmModal", {
                    title: t("shared.access.info_unathorized"),
                    description: t("shared.access.info_unathorized_desc"),
                    ctaLabel: t("shared.go_to_dashboard"),
                    cancelLabel: t("shared.log_out"),
                    size: "xl",
                });
                if (decision) {
                    // goto dashboard...
                    const { magicUrl } = await userService.forgeMagicLink(
                        store.getters["activeAppDashboardLink"]
                    );
                    window.location = magicUrl;
                } else {
                    // logout...
                    await loginService.logOut();
                }
                //console.log("DECISION is", decision);
                //handleErrorByMessage()(error);
            } else {
                setTimeout(() => {
                    window.location.href = loginService.generateLogInLink(lang);
                }, 1000);
                const loginError = {
                    errorToken: ERR_LOGIN_REQUIRED,
                    translatedMessage: mapAjaxError(ERR_LOGIN_REQUIRED, lang),
                };
                handleErrorByMessage()(loginError);
            }
        } else {
            handleErrorByMessage()(error);
        }
    };
}

export function handleErrorNotPublished() {
    return (error) => {
        if (window.location === window.parent.location) {
            if (error.errorToken === ERR_ACCESS_PLAN_BELOW_REQUIRED) {
                router.push({ name: RENDER_ROUTE_NAMES.notPublished });
            } else {
                router.push({ name: RENDER_ROUTE_NAMES.notFound });
            }
        } else {
            handleErrorByMessage()(error);
        }
    };
}

export function handleErrorUuidTaken() {
    return (error) => {
        if (error === "UUID_TAKEN") {
            store.commit("showAlert", {
                type: "wrong",
                title: store.getters.$l({
                    en: "This url is already taken",
                    pl: "Ten url jest już zajęty",
                }),
            });
            throw error;
        } else {
            handleErrorByMessage()(error);
        }
    };
}
