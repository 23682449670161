import { LOVE_FIRST_SEGMENT } from "@/apps/love/love.routes";
import router from "@/router";
import { LEGAL_FIRST_SEGMENT } from "@/apps/legal/legal.routes";
import store from "@/store";
import { COFFEE_FIRST_SEGMENT } from "@/apps/coffee/coffee.routes";
import { COOKIE_FIRST_SEGMENT } from "@/apps/cookie/cookie.routes";
import { FAQ_FIRST_SEGMENT } from "@/apps/faq/faq.routes";

import { t } from "@/i18n";
import { PLAYER_FIRST_SEGMENT } from "@/apps/player/player.routes";
import { PAGE_FIRST_SEGMENT } from "@/apps/page/page.routes";
import { PRICING_FIRST_SEGMENT } from "@/apps/pricing/pricing.routes";
import { TIMER_FIRST_SEGMENT } from "@/apps/timer/timer.routes";
import { userService } from "@/auth/service/user.service";
import { projectsService } from "@/shared/services/projects.service";

const BaseLayoutWithAlerts = () => import("@/shared/views/vMainLayout.vue");
const DEFAULT_PROJECT_NAME = "My sample project";
const DEFAULT_PROJECT_HANDLER = (res, appPath) => router.push(`/${appPath}/projects/${res.uuid}`);

const serviceRepoActions = {
    [COFFEE_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [COOKIE_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [FAQ_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [LEGAL_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [LOVE_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [PAGE_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [PLAYER_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [PRICING_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [TIMER_FIRST_SEGMENT]: {
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
};

function chooseServiceAction(appPathSegment) {
    const action = serviceRepoActions[appPathSegment];
    if (!action) {
        throw new Error("create action not found :(");
    }
    return action;
}

export function createProjectRoute() {
    return {
        path: "/:appName/project-create",
        async beforeEnter({ params = {}, query }) {
            const { appName } = params;
            const { name: nameFromQuery } = query;
            const { name, handler } = chooseServiceAction(appName);
            const computedName = nameFromQuery || name;
            // check if user is logged in:
            await store.dispatch("getUser");
            if (store.getters.logged) {
                setTimeout(async () => {
                    const decision = await store.dispatch("modals/showConfirmModal", {
                        title: t("shared.test_project_modal.title"),
                        description: t("shared.test_project_modal.desc"),
                        ctaLabel: t("shared.go_to_dashboard"),
                        cancelLabel: undefined,
                    });
                    if (decision) {
                        const { magicUrl } = await userService.forgeMagicLink(
                            store.getters.activeAppDashboardLink
                        );
                        window.location = magicUrl;
                    }
                }, 1000);
                return true;
            }
            const response = await projectsService.create({ tool: appName, name: computedName });
            await handler(response, appName);
        },
        component: BaseLayoutWithAlerts,
    };
}
