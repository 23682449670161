import { createApp } from "vue";
import vClickOutside from "click-outside-vue3";
import { VTooltip } from "floating-vue";
import "floating-vue/dist/style.css";

import App from "./App.vue";
import router from "@/router";
import store from "@/store";

import "@/shared/css/index.css";

import language from "@/plugins/language";
import { makeLogger, settledLogLevel } from "@/shared/consola";
import { i18n } from "@/i18n";

const logger = makeLogger("EasyTools");

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(language)
    .use(vClickOutside)
    .directive("tooltip", VTooltip)
    .mount("#app");

logger.info(`v_${process.env.VUE_APP_VERSION}[${settledLogLevel}]`);
