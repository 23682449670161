import { makeLogger } from "@/shared/consola";

const logger = makeLogger("timer.router");

import(/* webpackChunkName: "timer_app" */ "./timer.route.navigate.js");

const TimerApp = () => import(/* webpackChunkName: "timer_app" */ "./TimerApp.vue");
const TimerProjectPage = () =>
    import(/* webpackChunkName: "timer_app" */ "./pages/TimerProjectPage.vue");
const TimerListPage = () => import(/* webpackChunkName: "timer_app" */ "./pages/TimerListPage.vue");

// Sidebar menus:
const SidebarSettings = () =>
    import(/* webpackChunkName: "timer_app" */ "@/apps/timer/project/sidebar/SidebarSettings.vue");

export const TIMER_ROUTE_NAMES = {
    project: "timer-project",
    projectSettings: "timer-project-settings",
    list: "timer-list",
};

export const TIMER_PREVIEW_KIND = {
    timer: "timer",
};

export const TIMER_FIRST_SEGMENT = "timer";

export const timerRoutes = [
    {
        path: `/${TIMER_FIRST_SEGMENT}`,
        component: TimerApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: TIMER_ROUTE_NAMES.project,
                component: TimerProjectPage,
                children: [
                    {
                        path: "settings",
                        name: TIMER_ROUTE_NAMES.projectSettings,
                        component: SidebarSettings,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                TIMER_ROUTE_NAMES.projectSettings
                            );
                            return {
                                name: TIMER_ROUTE_NAMES.projectSettings,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: TIMER_ROUTE_NAMES.list,
                component: TimerListPage,
            },
        ],
    },
];
