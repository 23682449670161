import { makeLogger } from "@/shared/consola";

const logger = makeLogger("banner.router");

const BannerApp = () => import(/* webpackChunkName: "banner_app" */ "./BannerApp.vue");
const BannerProjectPage = () =>
    import(/* webpackChunkName: "banner_app" */ "./pages/BannerProjectPage.vue");
const BannerListPage = () =>
    import(/* webpackChunkName: "banner_app" */ "./pages/BannerListPage.vue");

// Sidebar menus:
const SidebarCode = () =>
    import(/* webpackChunkName: "banner_app" */ "@/apps/banner/project/sidebar/SidebarCode.vue");
const SidebarData = () =>
    import(/* webpackChunkName: "banner_app" */ "@/apps/banner/project/sidebar/SidebarData.vue");

export const BANNER_ROUTE_NAMES = {
    project: "banner-project",
    projectCode: "banner-project-code",
    projectData: "banner-project-data",
    list: "banner-list",
};

export const BANNER_PREVIEW_KIND = {
    image: "image",
    pdf: "pdf",
};

export const BANNER_FIRST_SEGMENT = "banner";

export const bannerRoutes = [
    {
        path: `/${BANNER_FIRST_SEGMENT}`,
        component: BannerApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: BANNER_ROUTE_NAMES.project,
                component: BannerProjectPage,
                children: [
                    {
                        path: "code",
                        name: BANNER_ROUTE_NAMES.projectCode,
                        component: SidebarCode,
                    },
                    {
                        path: "data",
                        name: BANNER_ROUTE_NAMES.projectData,
                        component: SidebarData,
                    },
                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                BANNER_ROUTE_NAMES.projectCode
                            );
                            return {
                                name: BANNER_ROUTE_NAMES.projectCode,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: BANNER_ROUTE_NAMES.list,
                component: BannerListPage,
            },
        ],
    },
];
