import { registerServiceErrors } from "@/shared/errors/ajax-errors-mapper";

registerServiceErrors({
    ERR_PROJECTS_PROJECT_NOT_FOUND: {
        pl: "Nie znaleziono projektu",
        en: "Project not found",
    },
    ERR_PROJECTS_NOT_AN_OWNER: {
        pl: "Nie posiadasz odpowiednich uprawnień",
        en: "Unauthorized",
    },
});
