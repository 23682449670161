import { loveRoutes } from "@/apps/love/love.routes";
import { legalRoutes } from "@/apps/legal/legal.routes";
import { faqRoutes } from "@/apps/faq/faq.routes";
import { cookieRoutes } from "@/apps/cookie/cookie.routes";
import { timerRoutes } from "@/apps/timer/timer.routes";
import { coffeeRoutes } from "@/apps/coffee/coffee.routes";
import { pricingRoutes } from "@/apps/pricing/pricing.routes";
import { playerRoutes } from "@/apps/player/player.routes";
import { pageRoutes } from "@/apps/page/page.routes";
import { bannerRoutes } from "@/apps/banner/banner.routes";
import { previewRoutes } from "@/views/preview/preview.routes";
import { renderRoutes } from "@/views/render/render.routes";
import { createProjectRoute } from "@/router/create-project.route";

export const appRoutes = [
    ...loveRoutes,
    ...legalRoutes,
    ...faqRoutes,
    ...cookieRoutes,
    ...timerRoutes,
    ...coffeeRoutes,
    ...pricingRoutes,
    ...playerRoutes,
    ...pageRoutes,
    ...bannerRoutes,
    ...previewRoutes,
    ...renderRoutes,
    createProjectRoute(), // make: /:appName/project-create
];
