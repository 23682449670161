import { makeLogger } from "@/shared/consola";

const logger = makeLogger("coffee.router");

import(/* webpackChunkName: "coffee_app" */ "./coffee.route.navigate.js");

const CoffeeApp = () => import(/* webpackChunkName: "coffee_app" */ "./CoffeeApp.vue");
const CoffeeProjectPage = () =>
    import(/* webpackChunkName: "coffee_app" */ "./pages/CoffeeProjectPage.vue");
const CoffeeListPage = () =>
    import(/* webpackChunkName: "coffee_app" */ "./pages/CoffeeListPage.vue");

// Sidebar menus:
const SidebarProduct = () =>
    import(/* webpackChunkName: "coffee_app" */ "@/apps/coffee/project/sidebar/SidebarProduct.vue");
const SidebarContent = () =>
    import(/* webpackChunkName: "coffee_app" */ "@/apps/coffee/project/sidebar/SidebarContent.vue");
const SidebarStyles = () =>
    import(/* webpackChunkName: "coffee_app" */ "@/apps/coffee/project/sidebar/SidebarStyles.vue");
const SidebarData = () =>
    import(/* webpackChunkName: "coffee_app" */ "@/apps/coffee/project/sidebar/SidebarData.vue");
const SidebarLayout = () =>
    import(/* webpackChunkName: "coffee_app" */ "@/apps/coffee/project/sidebar/SidebarLayout.vue");

export const COFFEE_ROUTE_NAMES = {
    project: "coffee-project",
    projectProduct: "coffee-project-product",
    projectContent: "coffee-project-content",
    projectStyles: "coffee-project-styles",
    projectLayout: "coffee-project-layout",
    projectData: "coffee-project-data",
    list: "coffee-list",
};

export const COFFEE_PREVIEW_KIND = {
    coffee: "coffee",
};

export const COFFEE_FIRST_SEGMENT = "coffee";

export const coffeeRoutes = [
    {
        path: `/${COFFEE_FIRST_SEGMENT}`,
        component: CoffeeApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: COFFEE_ROUTE_NAMES.project,
                component: CoffeeProjectPage,
                children: [
                    {
                        path: "product",
                        name: COFFEE_ROUTE_NAMES.projectProduct,
                        component: SidebarProduct,
                    },
                    {
                        path: "content",
                        name: COFFEE_ROUTE_NAMES.projectContent,
                        component: SidebarContent,
                    },
                    {
                        path: "styles",
                        name: COFFEE_ROUTE_NAMES.projectStyles,
                        component: SidebarStyles,
                    },
                    {
                        path: "layout",
                        name: COFFEE_ROUTE_NAMES.projectLayout,
                        component: SidebarLayout,
                    },
                    {
                        path: "data",
                        name: COFFEE_ROUTE_NAMES.projectData,
                        component: SidebarData,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                COFFEE_ROUTE_NAMES.projectContent
                            );
                            return {
                                name: COFFEE_ROUTE_NAMES.projectContent,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: COFFEE_ROUTE_NAMES.list,
                component: CoffeeListPage,
            },
        ],
    },
];
