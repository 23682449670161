import { createI18n } from "vue-i18n";
import pl from "./messages/pl.json";
import en from "./messages/en.json";
import renderPL from "./messages/render__pl.json";
import renderEN from "./messages/render__en.json";

const pluralRules = {
    pl: function (choice) {
        if (choice === 0) {
            return 0;
        }
        const teen = choice > 10 && choice < 20;
        const endsWithOne = choice % 10 === 1;
        if (!teen && endsWithOne) {
            return 1;
        }
        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
        }
        return 3;
    },
};

export const i18n = createI18n({
    legacy: false,
    locale: "pl",
    fallbackLocale: "en",
    messages: {
        en: { ...en, ...renderEN },
        pl: { ...pl, ...renderPL },
    },
    pluralRules,
});

export const t = (...args) => i18n.global.t(...args);
