import { makeLogger } from "@/shared/consola";

const logger = makeLogger("ajax-errors-mapper");
const ERROR_TABLE = {
    UNKNOWN_ERROR: {
        pl: "Nieznany błąd",
        en: "Unknown error",
    },
    // @dev: extend this not here, but with: registerServiceErrors
};

const mapErrorEntries =
    (lang) =>
    ([field, errorValue]) =>
        `${ERROR_TABLE.ERR_FIELD[lang]} ${field}: ${mapSingleError(errorValue, lang)}`;
const mapSingleError = (key, lang) => ERROR_TABLE?.[key]?.[lang] || key;

export function registerServiceErrors(errors) {
    for (const [key, value] of Object.entries(errors)) {
        ERROR_TABLE[key] = value;
    }
}

export function mapAjaxError(error, lang = "pl") {
    logger.trace("mapAjaxError", error, lang);
    if (!error) {
        console.warn("empty error provided from server");
        return ERROR_TABLE[error][lang];
    }
    const errorTable = Array.isArray(error)
        ? error
        : typeof error === "string"
          ? [error]
          : Object.entries(error).flatMap(mapErrorEntries(lang));
    logger.trace("mapAjaxError", errorTable);
    return errorTable.map((text) => mapSingleError(text, lang)).join("; ");
}
