export function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export function parseCode(text, darkMode) {
    return text
        .replace(/\\_/g, "{{{}}}")
        .replace(/(_(.+?)_)/g, "<i>$2</i>")
        .replace(/{{{}}}/g, "_")

        .replace(/\\\*/g, "{{{}}}")
        .replace(/(\*(.+?)\*)/g, "<b>$2</b>")
        .replace(/{{{}}}/g, "*")

        .replace(/\\\[/g, "{{{{{{")
        .replace(/\\\]/g, "}}}}}}")
        .replace(
            /(\[(.+?)\])/g,
            `<span class='${darkMode ? "bg-gray-950 text-white" : "bg-yellow-100"}'>$2</span>`
        )
        .replace(/{{{{{{/g, "[")
        .replace(/}}}}}}/g, "]");
}
export function copy(text) {
    const textarea = document.createElement("textarea");

    textarea.style = "position: absolute; left: -100vw;";
    textarea.value = text;

    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);
}
export function parseHtml(text) {
    return text.replaceAll("<!---->", "");
}
