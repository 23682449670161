import { makeLogger } from "@/shared/consola";
import { t } from "@/i18n";

const logger = makeLogger("modals.store");

const BASE_CONFIRM_DATA = () => ({
    title: "",
    description: "",
    ctaLabel: t("shared.save"),
    cancelLabel: t("shared.cancel"),
});

export default {
    namespaced: true,
    state: () => ({
        globalConfirm: {
            show: false,
            data: BASE_CONFIRM_DATA(),
            ctaCallback: () => logger.warn("confirm ctaCallback UNSET!"),
            cancelCallback: () => logger.warn("confirm cancelCallback UNSET!"),
            promisifiedReturn: () => Promise.resolve(false),
        },
    }),
    actions: {
        registerGlobalConfirmModalCallbacks({ state }, { cta, cancel }) {
            logger.trace("globalConfirm | registered");
            state.globalConfirm.promisifiedReturn = () =>
                new Promise((resolve) => {
                    state.globalConfirm.ctaCallback = () => {
                        state.globalConfirm.data = BASE_CONFIRM_DATA();
                        state.globalConfirm.show = false;
                        cta();
                        resolve(true);
                    };
                    state.globalConfirm.cancelCallback = () => {
                        state.globalConfirm.data = BASE_CONFIRM_DATA();
                        state.globalConfirm.show = false;
                        cancel();
                        resolve(false);
                    };
                });
        },
        async showConfirmModal({ state }, modalData = { title: "", description: "" }) {
            state.globalConfirm.data = {
                ...BASE_CONFIRM_DATA(),
                /*
                    ctaLabel: t("shared.save"),
                    cancelLabel: t("shared.cancel"),
                */
                ...modalData,
            };
            state.globalConfirm.show = true;
            return state.globalConfirm.promisifiedReturn();
        },
    },
};
