import { createConsola } from "consola";
import { isDevEnvironment, isStagingEnvironment } from "@/environments";

const LOG_LEVEL = {
    FATAL_AND_ERROR: 0,
    WARNINGS: 1,
    NORMAL: 2,
    INFORMATIONAL: 3, //prod
    DEBUG: 4, //stage
    TRACE: 5, //local-dev

    SILENT: -999,
    VERBOSE: 999,
    // https://www.npmjs.com/package/consola#user-content-log-level
};

export const settledLogLevel = isDevEnvironment
    ? LOG_LEVEL.TRACE
    : isStagingEnvironment
      ? LOG_LEVEL.DEBUG
      : LOG_LEVEL.INFORMATIONAL;

export const makeLogger = (tag = "global") => {
    return createConsola({
        defaults: {
            tag,
        },
        fancy: false,
        level: settledLogLevel,
    });
};
