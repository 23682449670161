import store from "@/store";
import { t } from "@/i18n";
import { makeLogger } from "@/shared/consola";

const logger = makeLogger("onNavigateAwayGuards");
const GUARDS = [];
const WATCHABLE_SEGMENTS = [];

export function registerNavigateAwayGuard(
    guardLogic = { segment: "", onSaveChanges: () => {}, onDropChanges: () => {} }
) {
    WATCHABLE_SEGMENTS.push(guardLogic.segment);
    GUARDS.push(guardLogic);
}

export async function onNavigateAwayGuards(to, from) {
    // Do not trigger guard when changes="ignore" query param is present
    if (to.query.changes && to.query.changes === "ignore") {
        delete to.query.changes;
        return true;
    }

    const [, entry] = from.path.split("/");
    const { activeAppHasChanges } = store.getters;
    // @Fix: Do not trigger guard when e.g. "just" query string replaced!
    const pageNameHasReallyChanged = to.name !== from.name;

    if (WATCHABLE_SEGMENTS.includes(entry) && activeAppHasChanges && pageNameHasReallyChanged) {
        const action = await store.dispatch("modals/showConfirmModal", {
            title: t("shared.unsaved_changes.title"),
            description: t("shared.unsaved_changes.details"),
            ctaLabel: t("shared.unsaved_changes.action_save"),
            cancelLabel: t("shared.unsaved_changes.action_drop"),
            size: "xl",
        });
        const guardToFire = GUARDS.find((g) => g.segment === entry);
        logger.trace("Decision about changes:", action ? "SAVE" : "DROP");
        if (action) {
            await guardToFire.onSaveChanges();
        } else {
            await guardToFire.onDropChanges();
        }
    }
    return true;
}
