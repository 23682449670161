import { presetThemesService } from "@/shared/services/preset-themes.service";
import { userThemesService } from "@/shared/services/user-themes.service";
import { makeLogger } from "@/shared/consola";
import { cloneDeep } from "@/shared/js/helpers.js";

// eslint-disable-next-line no-unused-vars
const logger = makeLogger("theme store");

export default {
    namespaced: true,
    state: () => ({
        userThemes: [],
    }),
    getters: {
        stylePresets: (state, getters, rootState, rootGetters) =>
            presetThemesService.getAllStylePresets(rootGetters.$l),
        colorPresets: (state, getters, rootState, rootGetters) =>
            presetThemesService.getAllColorPresets(rootGetters.$l),
        colorThemes: (state, getters) => [...state.userThemes, ...getters.colorPresets],
    },
    mutations: {
        removeTheme(state, payload) {
            let index = state.userThemes.map((x) => x.id).indexOf(payload.id);
            if (index > -1) {
                state.userThemes.splice(index, 1);
            }
        },
        setUserThemes(state, payload) {
            state.userThemes = payload;
        },
        addTheme(state, payload) {
            state.userThemes.push(payload);
        },
        updateTheme(state, payload) {
            let index = state.userThemes.map((x) => x.id).indexOf(payload.id);
            if (index > -1) {
                state.userThemes[index] = payload;
            }
        },
    },
    actions: {
        async getUserThemes({ rootState, commit }) {
            rootState.waiting = true;
            try {
                const themes = await userThemesService.getAllForUser();
                themes.forEach((theme) => {
                    theme.value = `user-${theme.id}`;
                });
                commit("setUserThemes", themes);
                rootState.waiting = false;
            } catch {
                /* eslint-disable-next-line no-empty */
            }
        },
        async removeTheme({ rootState, rootGetters, commit }, theme) {
            logger.trace("removeTheme", theme);
            rootState.waiting = true;

            try {
                await userThemesService.remove(theme.id);
                rootState.waiting = false;
                commit(
                    "showAlert",
                    {
                        type: "success",
                        title: rootGetters.$l({
                            en: "Theme removed successfully",
                            pl: "Motyw usunięty",
                        }),
                    },
                    { root: true }
                );
                commit("removeTheme", theme);
            } catch {
                /* eslint-disable-next-line no-empty */
            }
        },
        async createTheme({ rootState, rootGetters, commit }, theme) {
            rootState.waiting = true;
            theme.userId = rootGetters.userId;
            return new Promise((resolve, reject) => {
                userThemesService
                    .create(theme)
                    .then((newTheme) => {
                        commit(
                            "showAlert",
                            {
                                type: "success",
                                title: rootGetters.$l({
                                    en: "Theme created successfully",
                                    pl: "Motyw utworzony",
                                }),
                            },
                            { root: true }
                        );
                        commit("addTheme", newTheme);
                        rootState.waiting = false;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        updateTheme({ rootState, rootGetters, commit }, theme) {
            logger.trace("updateTheme", theme);
            rootState.waiting = true;

            const themeToSave = cloneDeep(theme);
            delete themeToSave.createdAt;
            delete themeToSave.updatedAt;
            delete themeToSave.value;
            delete themeToSave.id;

            return new Promise((resolve, reject) => {
                userThemesService
                    .update(theme.id, themeToSave)
                    .then(() => {
                        rootState.waiting = false;
                        commit(
                            "showAlert",
                            {
                                type: "success",
                                title: rootGetters.$l({
                                    en: "Theme updated successfully",
                                    pl: "Motyw zaktualizowany",
                                }),
                            },
                            { root: true }
                        );
                        commit("updateTheme", theme);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
    },
};
