<template>
    <div class="dark:text-white py-5 p-2 mt-2 border-2 rounded-xl border-red-400">
        {{ errorMessage }}
    </div>
</template>

<script>
export default {
    name: "AuthError",
    props: {
        error: {
            type: Error,
            default: () => ({}),
        },
    },
    methods: {
        mapError(key) {
            const MAP = {
                "EasyId Integration error": this.$l({
                    en: "Sign in error, please try again",
                    pl: "Błąd logowania, spróbuj ponownie",
                }),
                CODE_OR_CLIENT_ID_NOT_PROVIDED: this.$l({
                    en: "Authorization code is missing, please try again",
                    pl: "Brak kodu autoryzacyjnego, spróbuj ponownie",
                }),
                "Network Error": this.$l({
                    en: "Network Error, no connection with server",
                    pl: "Błąd sieci, brak połączenia z serwerem",
                }),
            };
            return MAP[key] || key;
        },
    },
    computed: {
        message() {
            if (this.error.isAxiosError) {
                return this.error?.response?.data?.message || this.error?.message;
            }
            return this.error.message;
        },
        errorMessage() {
            return this.mapError(this.message);
        },
    },
};
</script>
