import store from "@/store";
import { computed } from "vue";

export default {
    install: (app) => {
        app.config.globalProperties.$l = (val) => {
            return val[store.state.lang] || val["en"]; //english fallback
        };
        app.config.globalProperties.lang = computed(() => store.state.lang);
    },
};
