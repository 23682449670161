import { makeLogger } from "@/shared/consola";
import { t } from "@/i18n";
import { projectsService } from "@/shared/services/projects.service";

const logger = makeLogger("project.store");

export default {
    namespaced: true,
    actions: {
        async renameProject({ rootState, commit }, { tool, project, newName }) {
            rootState.waiting = true;
            try {
                await projectsService.rename({ tool, uuid: project.uuid, name: newName });
                commit(
                    "showAlert",
                    {
                        type: "success",
                        title: t("shared.project.renamed"),
                    },
                    { root: true }
                );
                window.location.reload();
            } catch (e) {
                return e; // from now on - we return error on failure to hande `modal` state properly (or close it if there is no error)
            }
        },
        async removeProject({ rootState, commit }, { tool, uuid }) {
            rootState.waiting = true;
            try {
                await projectsService.remove({ tool, uuid });
                logger.success("Project removed");
                commit(
                    "showAlert",
                    {
                        type: "success",
                        title: t("shared.project.removed"),
                    },
                    { root: true }
                );
                rootState.waiting = false;
            } catch (e) {
                return e;
            }
        },
        async changeUuid({ rootState, commit }, { tool, uuid, newUuid }) {
            rootState.waiting = true;

            try {
                const project = await projectsService.changeUuid({ tool, uuid, newUuid });
                commit(
                    "showAlert",
                    {
                        type: "success",
                        title: t("shared.change_url.changed"),
                    },
                    { root: true }
                );
                setTimeout(() => {
                    window.location.href = project.url;
                }, 100);
            } catch {
                rootState.waiting = false;
            }
        },
    },
};
