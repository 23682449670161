import { makeLogger } from "@/shared/consola";

const logger = makeLogger("legal.router");

import(/* webpackChunkName: "legal_app" */ "./legal.route.navigate.js");

const LegalApp = () => import(/* webpackChunkName: "legal_app" */ "./LegalApp.vue");
const LegalProjectPage = () =>
    import(/* webpackChunkName: "legal_app" */ "./pages/LegalProjectPage.vue");
const LegalListPage = () => import(/* webpackChunkName: "legal_app" */ "./pages/LegalListPage.vue");

// Sidebar menus:
const SidebarCompany = () =>
    import(/* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarCompany.vue");
const SidebarFooterNavigation = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarFooterNavigation.vue"
    );
const SidebarFooterContent = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarFooterContent.vue"
    );
const SidebarFooterSocials = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarFooterSocials.vue"
    );

const SidebarStyles = () =>
    import(/* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarStyles.vue");
const TermsSettings = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/pages/TermsSettings.vue"
    );
const PrivacySettings = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/pages/PrivacySettings.vue"
    );
const AboutSettings = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/pages/AboutSettings.vue"
    );
const NdaSettings = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/documents/NdaSettings.vue"
    );

export const LEGAL_ROUTE_NAMES = {
    project: "legal-project",
    projectCompany: "legal-project-company",
    projectFooterNavigation: "legal-project-footer-navigation",
    projectFooterContent: "legal-project-footer-content",
    projectFooterSocials: "legal-project-footer-socials",
    projectStyles: "legal-project-styles",
    projectPagesTerms: "legal-project-pages-terms",
    projectPagesPolicy: "legal-project-pages-policy",
    projectPagesAbout: "legal-project-pages-about",
    projectDocumentsNda: "legal-project-documents-nda",
    list: "legal-list",
};

export const LEGAL_PREVIEW_KIND = {
    terms: "terms",
    privacy: "privacy",
    about: "about",
    footer: "footer",
    nda: "nda",
};

export const LEGAL_FIRST_SEGMENT = "legal";

export const legalRoutes = [
    {
        path: `/${LEGAL_FIRST_SEGMENT}`,
        component: LegalApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: LEGAL_ROUTE_NAMES.projectPage,
                component: LegalProjectPage,
                children: [
                    {
                        path: "company",
                        name: LEGAL_ROUTE_NAMES.projectCompany,
                        component: SidebarCompany,
                    },
                    {
                        path: "footer-navigation",
                        name: LEGAL_ROUTE_NAMES.projectFooterNavigation,
                        component: SidebarFooterNavigation,
                        meta: { menuCategory: "footer" },
                    },
                    {
                        path: "footer-content",
                        name: LEGAL_ROUTE_NAMES.projectFooterContent,
                        component: SidebarFooterContent,
                        meta: { menuCategory: "footer" },
                    },
                    {
                        path: "footer-socials",
                        name: LEGAL_ROUTE_NAMES.projectFooterSocials,
                        component: SidebarFooterSocials,
                        meta: { menuCategory: "footer" },
                    },
                    {
                        path: "pages-terms",
                        name: LEGAL_ROUTE_NAMES.projectPagesTerms,
                        component: TermsSettings,
                    },
                    {
                        path: "pages-policy",
                        name: LEGAL_ROUTE_NAMES.projectPagesPolicy,
                        component: PrivacySettings,
                    },
                    {
                        path: "pages-about",
                        name: LEGAL_ROUTE_NAMES.projectPagesAbout,
                        component: AboutSettings,
                    },
                    {
                        path: "documents-nda",
                        name: LEGAL_ROUTE_NAMES.projectDocumentsNda,
                        component: NdaSettings,
                    },
                    {
                        path: "styles",
                        name: LEGAL_ROUTE_NAMES.projectStyles,
                        component: SidebarStyles,
                    },
                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                LEGAL_ROUTE_NAMES.projectCompany
                            );
                            return {
                                name: LEGAL_ROUTE_NAMES.projectCompany,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: LEGAL_ROUTE_NAMES.list,
                component: LegalListPage,
            },
        ],
    },
];
